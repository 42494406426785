.img_center {
    margin-top:22rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);
    padding: 4rem;
}

.error_image {
width: 15%;
}