header {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 5.5rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ==== CTA ==== */
.cta {
    margin-top: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ==== HEADER SOCIAL ==== */
.header_socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ==== ME ==== */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 34rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 5rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}



/* ==== SCROLL DOWN ==== */

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    header {
        height: 105vh;
        height: calc(var(--vh, 1vh) * 105);
        padding-top: 4rem;
    }

}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .me {
        height: 32rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 3rem;
        padding: 3rem 1.5rem 1.5rem 1.5rem;
    }
    header {
        height: 125vh;
        height: calc(var(--vh, 1vh) * 125);
        padding-top: 3rem;
    }

    .header_socials,
    .scroll_down {
        display: none;
    }
}