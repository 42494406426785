.container {
    width:  var(--container-width-lg);
}
.privacy_policy_text {
    text-align: justify;
}

.privacy_policy_text > h1{
    margin-top: 5rem;
    margin-bottom: 2rem;
}